import React from "react";

const ExperienceSection = () => {
  return (
    <section className="relative md:py-24 py-16">
      <div className="container">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
          <div className="lg:col-span-5 md:col-span-6">
            <div className="grid grid-cols-12 gap-6 items-center">
              <div className="col-span-6">
                <div className="grid grid-cols-1 gap-6">
                  <img
                    src="assets/images/about/ab03.jpg"
                    className="shadow rounded-md"
                    alt=""
                  />
                  <img
                    src="assets/images/about/ab02.jpg"
                    className="shadow rounded-md"
                    alt=""
                  />
                </div>
              </div>

              <div className="col-span-6">
                <div className="grid grid-cols-1 gap-6">
                  <img
                    src="assets/images/about/ab01.jpg"
                    className="shadow rounded-md"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-7 md:col-span-6">
            <div className="ltr:lg:ml-5 rtl:lg:mr-5">
              <div className="flex mb-4">
                <span className="text-indigo-600 text-2xl font-bold mb-0">
                  <span
                    className="counter-value text-6xl font-bold"
                    data-target="15"
                  >
                    7
                  </span>
                  +
                </span>
                <span className="self-end font-medium ltr:ml-2 rtl:mr-2">
                  Years <br /> Experience
                </span>
              </div>

              <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                Who we are ?
              </h3>

              <p className="text-slate-400 max-w-xl">
                We are a group of professional engineers and marketers who founded this company with the goal of providing clients with affordable, high-quality services. We are here to make a big name by providing world-class services. 
              </p>
<br/>
              <p className="text-slate-400 max-w-xl">
                We specialize in multiple technologies like Solidity, Nodejs/NestJS, ReactJ, Angular2+, PHP Laravel, WordPress, Asp.net, Relational and Non-Relational Database PostgreSQL, MySQL, MongoDB, and SQLite.
              </p>
<br/>
              <p className="text-slate-400 max-w-xl">
                We have experience in varied domains which include DeFI, Insurance, PrintMedia, E-commerce, Supply Chain, Manufacturing, etc.
              </p>
<br/>
              <p className="text-slate-400 max-w-xl">
                We don't want to brag about what we're doing now, but we want you to keep an eye on this section as we add customer success stories. 
              </p>

              <div className="mt-6">
                <a href="/contact"
                  className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2" >
                  <i className="uil uil-envelope"></i> Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default ExperienceSection;
