import React from "react";

const BlogHeader = () => {
  return (
    <div className="p-6 rounded-md shadow dark:shadow-gray-800">
      <img src="assets/images/blog/slide02.jpg" className="rounded-md" alt="" />

      <div className="mt-6">
        <p className="text-slate-400">
          The most well-known dummy text is the 'Lorem Ipsum', which is said to
          have originated in the 16th century. Lorem Ipsum is composed in a
          pseudo-Latin language which more or less corresponds to 'proper'
          Latin. It contains a series of real Latin words. This ancient dummy
          text is also incomprehensible, but it imitates the rhythm of most
          European languages in Latin script.
        </p>
        <p className="text-slate-400 italic border-x-4 border-indigo-600 ltr:rounded-tl-xl rtl:rounded-tr-xl ltr:rounded-br-xl rtl:rounded-bl-xl mt-3 p-3">
          " There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          "
        </p>
        <p className="text-slate-400 mt-3">
          The advantage of its Latin origin and the relative meaninglessness of
          Lorum Ipsum is that the text does not attract attention to itself or
          distract the viewer's attention from the layout.
        </p>
      </div>
    </div>
  );
};

export default BlogHeader;
