import React from "react";

const HeroSection = ({ img }) => {
  return (
    <section
      className="relative table w-full py-32 lg:py-36 bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="absolute inset-0 bg-black opacity-80"></div>
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center mt-10">
          <h3 className="mb-3 text-3xl leading-normal font-medium text-white">
            My Very Minimal <br /> Interior Design Ideas
          </h3>

          <ul className="list-none mt-6">
            <li className="inline-block font-semibold text-white/50 mx-5">
              {" "}
              <span className="text-white block">Client :</span>{" "}
              <span className="block">Calvin Carlo</span>
            </li>
            <li className="inline-block font-semibold text-white/50 mx-5">
              {" "}
              <span className="text-white block">Date :</span>{" "}
              <span className="block">23th May, 2022</span>
            </li>
            <li className="inline-block font-semibold text-white/50 mx-5">
              {" "}
              <span className="text-white block">Time :</span>{" "}
              <span className="block">8 Min Read</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="absolute text-center z-10 bottom-5 right-0 left-0 mx-3">
        <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
          <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
            <a href="/">NambiTech</a>
          </li>
          <li
            className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
            aria-current="page"
          >
            Blogs
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HeroSection;
