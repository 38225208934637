import React from "react";
import {
  Code,
  Eye,
  Feather,
  Globe,
  Heart,
  Layout,
  Monitor,
  Settings,
  UserCheck,
} from "react-feather";

const FeatureSection = () => {
  return (
    <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            Our Work
          </h3>

          {/* <p className="text-slate-400 max-w-xl mx-auto">
            Start working with Tailwind CSS that can provide everything you need
            to generate awareness, drive traffic, connect.
          </p> */}
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-8">
          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Monitor className="h-5 w-5 rotate-45" />
              {/* <i data-feather="monitor" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">Decentralized Exchange</h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Heart className="h-5 w-5 rotate-45" />
              {/* <i data-feather="heart" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">
                ICO
              </h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Eye className="h-5 w-5 rotate-45" />
              {/* <i data-feather="eye" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">IDO</h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Layout className="h-5 w-5 rotate-45" />
              {/* <i data-feather="layout" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">
                Yield Farming
              </h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Feather className="h-5 w-5 rotate-45" />
              {/* <i data-feather="feather" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">Yield Aggregator</h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Code className="h-5 w-5 rotate-45" />
              {/* <i data-feather="code" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">Lending Platforms</h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <UserCheck className="h-5 w-5 rotate-45" />
              {/* <i data-feather="user-check" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">Lottery</h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Globe className="h-5 w-5 rotate-45" />
              {/* <i data-feather="globe" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">NFT Marketplace</h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Settings className="h-5 w-5 rotate-45" />
              {/* <i data-feather="settings" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">Staking and Locking Platforms</h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <UserCheck className="h-5 w-5 rotate-45" />
              {/* <i data-feather="user-check" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">Auction</h4>
            </div>
          </div>

          <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full ltr:mr-3 rtl:ml-3">
              <Globe className="h-5 w-5 rotate-45" />
              {/* <i data-feather="globe" className="h-5 w-5 rotate-45"></i> */}
            </div>
            <div className="flex-1">
              <h4 className="mb-0 text-lg font-medium">Tokenization of Real Estate </h4>
            </div>
          </div>
        </div>

        {/* <div className="grid grid-cols-1 justify-center">
          <div className="mt-6 text-center">
            <a
              href="page-services.html"
              className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2"
            >
              See More <i className="uil uil-arrow-right"></i>
            </a>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default FeatureSection;
