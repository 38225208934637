import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import contact from '../../assets/images/company/aboutus.jpg';
import MetaData from "../../components/MetaData";
import { Book, Mail, MessageCircle, User } from "react-feather";

const Contact = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <>
    {/* <MetaData title="NambiTech: A Blockchain Company" /> */}
      <Navbar isLight={true} />
      <section className="relative table w-full py-36 bg-center bg-no-repeat" style={{backgroundImage: `url(${contact})`}}>
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal tracking-wide leading-normal font-medium text-white">
              Contact Us
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 right-0 left-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <a href="index.html">NambiTech</a>
            </li>
            <li
              className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Contact Us
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-4 py-4">

        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src="assets/images/contact.svg" alt="" />
            </div>

            <div className="lg:col-span-5 md:col-span-6 mt-8 md:mt-0">
              <div className="ltr:lg:ml-5 rtl:lg:mr-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">
                    Get in touch !
                  </h3>

                  <form
                    method="post"
                    name="myForm"
                    id="myForm"
                    onsubmit="return validateForm()"
                  >
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <div className="ltr:text-left rtl:text-right">
                          <label for="name" className="font-semibold">
                            Your Name:
                          </label>
                          <div className="form-icon relative mt-2">
                            <User className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"/>
                            {/* <i
                              data-feather="user"
                              className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"
                            ></i> */}
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-input ltr:pl-11 rtl:pr-11"
                              placeholder="Name :"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <div className="ltr:text-left rtl:text-right">
                          <label for="email" className="font-semibold">
                            Your Email:
                          </label>
                          <div className="form-icon relative mt-2">
                            <Mail className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"/>
                            {/* <i
                              data-feather="mail"
                              className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"
                            ></i> */}
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-input ltr:pl-11 rtl:pr-11"
                              placeholder="Email :"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <div className="ltr:text-left rtl:text-right">
                          <label for="subject" className="font-semibold">
                            Your Question:
                          </label>
                          <div className="form-icon relative mt-2">
                            <Book className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"/>
                            {/* <i
                              data-feather="book"
                              className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"
                            ></i> */}
                            <input
                              name="subject"
                              id="subject"
                              className="form-input ltr:pl-11 rtl:pr-11"
                              placeholder="Subject :"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-5">
                        <div className="ltr:text-left rtl:text-right">
                          <label for="comments" className="font-semibold">
                            Your Comment:
                          </label>
                          <div className="form-icon relative mt-2">
                            <MessageCircle className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"/>
                            {/* <i
                              data-feather="message-circle"
                              className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"
                            ></i> */}
                            <textarea
                              name="comments"
                              id="comments"
                              className="form-input ltr:pl-11 rtl:pr-11 h-28"
                              placeholder="Message :"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                    >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            {/* <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-phone"></i>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Phone</h5>
                <p className="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p>

                <div className="mt-5">
                  <a
                    href="tel:+152534-468-854"
                    className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    +152 534-468-854
                  </a>
                </div>
              </div>
            </div> */}

            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-envelope"></i>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Email</h5>
                <p className="text-slate-400 mt-3">
                  Connect with us on
                </p>

                <div className="mt-5">
                  <a
                    href="mailto:contact@nambitech.com"
                    className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    contact@nambitech.com
                  </a>
                </div>
              </div>
            </div>

            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-map-marker"></i>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Location</h5>
                <p className="text-slate-400 mt-3">
                Zenia Building, Hiranandani Circle, Hiranandani Business Park, Off Ghodbunder Road, Thane, MH 400607
                </p>

                {/* <div className="mt-5">
                  <a
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    data-type="iframe"
                    className="video-play-icon read-more lightbox btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    View on Google map
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>


      </section>

      {/* <div className="container-fluid relative">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
              title="address"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style={{border: "0"}}
              className="w-full h-[500px]"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default Contact;
