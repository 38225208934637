import React from "react";
import Anchor from "../../components/Anchor";

const Comment = () => {
  return (
    <div className="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
      <h5 className="text-lg font-semibold">Comments:</h5>

      <div className="mt-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src="assets/images/client/01.jpg"
              className="h-11 w-11 rounded-full shadow"
              alt=""
            />

            <div className="ltr:ml-3 rtl:mr-3 flex-1">
              <Anchor
                href=""
                className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out"
              >
                Calvin Carlo
              </Anchor>
              <p className="text-sm text-slate-400">6th May 2022 at 01:25 pm</p>
            </div>
          </div>

          <Anchor
            href=""
            className="text-slate-400 hover:text-indigo-600 transition-all duration-500 ease-in-out ltr:ml-5 rtl:mr-5"
          >
            <i className="mdi mdi-reply"></i> Reply
          </Anchor>
        </div>
        <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
          <p className="text-slate-400 italic">
            " There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form, by injected
            humour "
          </p>
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src="assets/images/client/02.jpg"
              className="h-11 w-11 rounded-full shadow"
              alt=""
            />

            <div className="ltr:ml-3 rtl:mr-3 flex-1">
              <Anchor
                href=""
                className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out"
              >
                Calvin Carlo
              </Anchor>
              <p className="text-sm text-slate-400">6th May 2022 at 01:25 pm</p>
            </div>
          </div>

          <Anchor
            href=""
            className="text-slate-400 hover:text-indigo-600 transition-all duration-500 ease-in-out ltr:ml-5 rtl:mr-5"
          >
            <i className="mdi mdi-reply"></i> Reply
          </Anchor>
        </div>
        <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
          <p className="text-slate-400 italic">
            " There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form, by injected
            humour "
          </p>
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src="assets/images/client/03.jpg"
              className="h-11 w-11 rounded-full shadow"
              alt=""
            />

            <div className="ltr:ml-3 rtl:mr-3 flex-1">
              <Anchor
                href=""
                className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out"
              >
                Calvin Carlo
              </Anchor>
              <p className="text-sm text-slate-400">6th May 2022 at 01:25 pm</p>
            </div>
          </div>

          <Anchor
            href=""
            className="text-slate-400 hover:text-indigo-600 transition-all duration-500 ease-in-out ltr:ml-5 rtl:mr-5"
          >
            <i className="mdi mdi-reply"></i> Reply
          </Anchor>
        </div>
        <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
          <p className="text-slate-400 italic">
            " There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form, by injected
            humour "
          </p>
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src="assets/images/client/04.jpg"
              className="h-11 w-11 rounded-full shadow"
              alt=""
            />

            <div className="ltr:ml-3 rtl:mr-3 flex-1">
              <Anchor
                href=""
                className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out"
              >
                Calvin Carlo
              </Anchor>
              <p className="text-sm text-slate-400">6th May 2022 at 01:25 pm</p>
            </div>
          </div>

          <Anchor
            href=""
            className="text-slate-400 hover:text-indigo-600 transition-all duration-500 ease-in-out ltr:ml-5 rtl:mr-5"
          >
            <i className="mdi mdi-reply"></i> Reply
          </Anchor>
        </div>
        <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
          <p className="text-slate-400 italic">
            " There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form, by injected
            humour "
          </p>
        </div>
      </div>
    </div>
  );
};

export default Comment;
