import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import services from "../../assets/images/services.jpg";
// import Anchor from "../../components/Anchor";
// import { Link } from "react-router-dom";
// import MetaData from "../../components/MetaData";
import HeroSection from "./HeroSection";
import OurServices from "./OurServices";
import LatestWorks from "./LatestWorks";
import UserFeedback from "./UserFeedback";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <MetaData title="NambiTech: A Blockchain Company" /> */}
      <Navbar isLight={true} />
      {/* Hero section */}
      <HeroSection img={services} />
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="relative md:py-24 py-16">
        {/* Our Services */}
        <OurServices/>
        {/* <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 items-end">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Latest Projects & Works
            </h3>
            <p className="text-slate-400 max-w-xl">
              Explore and learn more about everything from machine learning and
              global payments to scaling your team.
            </p>
          </div>
        </div> */}
        {/* latest work */}
        {/* <LatestWorks/> */}
        {/* what says user */}
        {/* <UserFeedback/> */}
        {/* <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h6 className="text-indigo-600 text-sm font-bold mb-2">
              Available for freelance projects
            </h6>
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Do you have digital project? <br /> Let's talk.
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Start working with Techwind that can provide everything you need
              to generate awareness, drive traffic, connect.
            </p>

            <div className="mt-6">
              <a
                href="contact-one.html"
                className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4"
              >
                <i className="uil uil-phone"></i> Contact us
              </a>
            </div>
          </div>
        </div> */}
      </section>
      <Footer />
    </>
  );
};

export default Services;
