/* eslint-disable no-script-url */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Anchor from "../Anchor";
import MetaData from "../MetaData";
export function getClosest(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
}
export function activateMenu() {
  var menuItems = document.getElementsByClassName("sub-menu-item");
  if (menuItems) {
    var matchingMenuItem = null;
    for (var idx = 0; idx < menuItems.length; idx++) {
      if (menuItems[idx].href === window.location.href) {
        matchingMenuItem = menuItems[idx];
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");

      var immediateParent = getClosest(matchingMenuItem, "li");

      if (immediateParent) {
        immediateParent.classList.add("active");
      }

      var parent = getClosest(immediateParent, ".child-menu-item");
      if (parent) {
        parent.classList.add("active");
      }

       parent = getClosest(parent || immediateParent, ".parent-menu-item");

      if (parent) {
        parent.classList.add("active");

        let parentMenuitem = parent.querySelector(".menu-item");
        if (parentMenuitem) {
          parentMenuitem.classList.add("active");
        }

        var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
        if (parentOfParent) {
          parentOfParent.classList.add("active");
        }
      } else {
         parentOfParent = getClosest(
          matchingMenuItem,
          ".parent-parent-menu-item"
        );
        if (parentOfParent) {
          parentOfParent.classList.add("active");
        }
      }
    }
  }
}

const Navbar = ({ isHome, isLight }) => {
  function toggleMenu() {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  }
  useEffect(() => {
    function windowScroll() {
      const navbar = document.getElementById("topnav");
      if (navbar != null) {
        if (
          document.body.scrollTop >= 50 ||
          document.documentElement.scrollTop >= 50
        ) {
          navbar.classList.add("nav-sticky");
        } else {
          navbar.classList.remove("nav-sticky");
        }
      }
    }

    window.addEventListener("scroll", (ev) => {
      ev.preventDefault();
      windowScroll();
    });
    // clickable menu
    if (document.getElementById("navigation")) {
      var elements = document
        .getElementById("navigation")
        .getElementsByTagName("a");
      for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].onclick = function (elem) {
          if (elem.target.getAttribute("href") === "javascript:void(0)") {
            var submenu = elem.target.nextElementSibling.nextElementSibling;
            submenu.classList.toggle("open");
          }
        };
      }
    }
  }, []);
  return (
    <>
    <MetaData title="NambiTech: A Blockchain Company" />
      {/* <!-- Start Navbar --> */}
      <nav id="topnav" className="defaultscroll is-sticky">
        <div className="container">
          {/* <!-- Logo container--> */}
          {isHome && (
            <Link className="logo ltr:pl-0 rtl:pr-0" to="/">
              <img
                src={require("../../assets/images/logo-light2.png")}
                className="inline-block dark:hidden w-[200px]"
                alt=""
              />
              <img
                src={require("../../assets/images/logo-dark2.png")}
                className="hidden dark:inline-block w-[200px]"
                alt=""
              />
            </Link>
          )}

          {isLight && (
            <Link className="logo ltr:pl-0 rtl:pr-0" to="/">
              <span className="inline-block dark:hidden">
                <img
                  src={require("../../assets/images/logo-light2.png")}
                  className="l-dark w-[200px]"
                  height="24"
                  alt=""
                />
                <img
                  src={require("../../assets/images/logo-dark2.png")}
                  className="l-light w-[200px]"
                  height="24"
                  alt=""
                />
              </span>
              <img
                src={require("../../assets/images/logo-dark2.png")}
                height="24"
                className="hidden dark:inline-block w-[200px]"
                alt=""
              />
            </Link>
          )}

          {/* <!-- End Logo container--> */}
          <div className="menu-extras">
            <div className="menu-item">
              {/* <!-- Mobile menu toggle--> */}
              <Link
                to="#"
                className="navbar-toggle"
                id="isToggle"
                onClick={toggleMenu}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
              {/* <!-- End mobile menu toggle--> */}
            </div>
          </div>

          {/* <!--Login button Start--> */}
          {/* <ul className="buy-button list-none mb-0">
            <li className="inline mb-0">
              {isLight ? (
                <Anchor>
                  <span className="login-btn-primary">
                    <span className="btn btn-icon rounded-full bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white">
                      <Settings className="h-4 w-4"/>
                    </span>
                  </span>
                  <span className="login-btn-light">
                    <span className="btn btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <Settings className="h-4 w-4"/>
                    </span>
                  </span>
                </Anchor>
              ) : (
                <Anchor className="btn btn-icon rounded-full bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white">
                  <Settings className="h-4 w-4"/>
                </Anchor>
              )}
            </li>

            <li className="inline ltr:pl-1 rtl:pr-1 mb-0">
              {isLight ? (
                <a href="https://1.envato.market/techwind" rel="noreferrer" target="_blank">
                  <div className="login-btn-primary">
                    <span className="btn btn-icon rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white">
                      <ShoppingCart className="h-4 w-4"/>
                    </span>
                  </div>
                  <div className="login-btn-light">
                    <span className="btn btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <ShoppingCart className="h-4 w-4"/>
                    </span>
                  </div>
                </a>
              ) : (
                <a
                  href="https://1.envato.market/techwind"
                  target="_blank"
                  className="btn btn-icon rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
                  rel="noreferrer"
                >
                  <ShoppingCart className="h-4 w-4"/>
                </a>
              )}
            </li>
          </ul> */}
          {/* <!--Login button End--> */}

          <div id="navigation">
            {/* <!-- Navigation Menu-->    */}
            <ul className={`navigation-menu ${isLight ? "nav-light" : ""}`}>
              <li>
                <Link to="/" className="sub-menu-item">
                  Home
                </Link>
              </li>
              {/* <li>
                <Link to="/portfolio" className="sub-menu-item">
                  Portfolio
                </Link>
              </li> */}
              <li className="has-submenu parent-menu-item">
                <Anchor href="javascript:void(0)">Company</Anchor>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <Link to="/about-us" className="sub-menu-item">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/team" className="sub-menu-item">
                      Team
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/testimonial" className="sub-menu-item">
                      Testimonial
                    </Link>
                  </li> */}
                </ul>
              </li>

              {/* <li className="has-submenu parent-parent-menu-item">
                <Anchor href="javascript:void(0)">Landing</Anchor>
                <span className="menu-arrow"></span>

                <ul className="submenu megamenu">
                  <li>
                    <ul>
                      <li>
                        <Anchor className="sub-menu-item">
                          Saas{" "}
                          <span className="bg-emerald-600 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ltr:ml-1 rtl:mr-1">
                            Animation
                          </span>
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          classNameic Saas{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Modern Saas{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Application
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          classNameic App{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Smartwatch
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Marketing
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          SEO Agency{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Payments{" "}
                          <span className="bg-orange-600 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ltr:ml-1 rtl:mr-1">
                            New
                          </span>
                        </Anchor>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          IT Solution
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          It Solution Two{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Digital Agency
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Job
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Restaurent
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Hosting
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Gym{" "}
                          <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ltr:ml-1 rtl:mr-1">
                            Dark
                          </span>
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          NFT Marketplace{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Hotel & Resort{" "}
                          <span className="bg-orange-600 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ltr:ml-1 rtl:mr-1">
                            New
                          </span>
                        </Anchor>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul>
                      <li>
                        <Anchor className="sub-menu-item">
                          Startup
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Business
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Corporate
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Corporate Two{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Real Estate
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Consulting{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Insurance{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Construction{" "}
                        </Anchor>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul>
                      <li>
                        <Anchor className="sub-menu-item">
                          Personal
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Portfolio
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Photography{" "}
                          <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ltr:ml-1 rtl:mr-1">
                            Dark
                          </span>
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Studio
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Co-Woriking
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Online Courses{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Hospital
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Event/Conference{" "}
                        </Anchor>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul>
                      <li>
                        <Anchor className="sub-menu-item">
                          Cryptocurrency{" "}
                          <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ltr:ml-1 rtl:mr-1">
                            Dark
                          </span>
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Landing One
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Landing Two
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Landing Three
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Landing Four
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Service Provider
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Food Blog{" "}
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Blog{" "}
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}

              {/* <li className="has-submenu parent-parent-menu-item">
                <Anchor href="javascript:void(0)">Pages</Anchor>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Company </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/about" className="sub-menu-item">
                          {" "}
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/services" className="sub-menu-item">
                          Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/team" className="sub-menu-item">
                          {" "}
                          Team
                        </Link>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Pricing
                        </Anchor>
                      </li>
                      <li>
                        <Link 
                        to="/testimonial"
                          className="sub-menu-item"
                        >
                          Testimonial{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Accounts</Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor className="sub-menu-item">
                          User Profile
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Billing
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Payment
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Invoice
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Social
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Notification
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Setting
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Real Estate</Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Listing
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Property Detail
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Courses </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor className="sub-menu-item">
                          Course Listing
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Course Detail
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> NFT Market </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor className="sub-menu-item">
                          Explore
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Auction
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Collections
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Creators
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Wallet
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Create NFT
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Single NFT
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Anchor className="sub-menu-item">
                      Food Recipe{" "}
                    </Anchor>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Photography </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          About Us
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Portfolio
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Job / Careers </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor className="sub-menu-item">
                          All Jobs
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Job Detail
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Job Apply
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Job Candidates
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Candidate Detail
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          All Companies
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Company Detail
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Helpcenter </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor className="sub-menu-item">
                          Overview
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          FAQs
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Guides
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Support
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Blog </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/blog" className="sub-menu-item">
                          Blogs
                        </Link>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Blogs & Sidebar
                        </Anchor>
                      </li>
                      <li>
                        <Link to="/blog-detail" className="sub-menu-item">
                          Blog Detail
                        </Link>
                      </li>
                      <li className="has-submenu child-menu-item">
                        <Anchor href="javascript:void(0)"> Blog Posts </Anchor>
                        <span className="submenu-arrow"></span>
                        <ul className="submenu">
                          <li>
                            <Anchor
                              className="sub-menu-item"
                            >
                              Standard Post
                            </Anchor>
                          </li>
                          <li>
                            <Anchor
                              className="sub-menu-item"
                            >
                              Slider Post
                            </Anchor>
                          </li>
                          <li>
                            <Anchor
                              className="sub-menu-item"
                            >
                              Gallery Post
                            </Anchor>
                          </li>
                          <li>
                            <Anchor
                              className="sub-menu-item"
                            >
                              Youtube Post
                            </Anchor>
                          </li>
                          <li>
                            <Anchor
                              className="sub-menu-item"
                            >
                              Vimeo Post
                            </Anchor>
                          </li>
                          <li>
                            <Anchor
                              className="sub-menu-item"
                            >
                              Audio Post
                            </Anchor>
                          </li>
                          <li>
                            <Anchor
                              className="sub-menu-item"
                            >
                              Blockquote
                            </Anchor>
                          </li>
                          <li>
                            <Anchor
                              className="sub-menu-item"
                            >
                              Left Sidebar
                            </Anchor>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Email Template</Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Confirmation
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Reset Password
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Alert
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Invoice
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Auth Pages </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor className="sub-menu-item">
                          Login
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Signup
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Reset Password
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Lock Screen
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Utility </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor className="sub-menu-item">
                          Terms of Services
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Privacy Policy
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Special</Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Coming Soon
                        </Anchor>
                      </li>
                      <li>
                        <Anchor
                          className="sub-menu-item"
                        >
                          Maintenance
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Error
                        </Anchor>
                      </li>
                      <li>
                        <Anchor className="sub-menu-item">
                          Thank you
                        </Anchor>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Contact </Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <a href="contact-detail.html" className="sub-menu-item">
                          Contact Detail
                        </a>
                      </li>
                      <li>
                        <a href="contact-one.html" className="sub-menu-item">
                          Contact One
                        </a>
                      </li>
                      <li>
                        <a href="contact-two.html" className="sub-menu-item">
                          Contact Two
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Anchor href="javascript:void(0)"> Multi Level Menu</Anchor>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Anchor
                          href="javascript:void(0)"
                          className="sub-menu-item"
                        >
                          Level 1.0
                        </Anchor>
                      </li>
                      <li className="has-submenu child-menu-item">
                        <Anchor href="javascript:void(0)"> Level 2.0 </Anchor>
                        <span className="submenu-arrow"></span>
                        <ul className="submenu">
                          <li>
                            <Anchor
                              href="javascript:void(0)"
                              className="sub-menu-item"
                            >
                              Level 2.1
                            </Anchor>
                          </li>
                          <li>
                            <Anchor
                              href="javascript:void(0)"
                              className="sub-menu-item"
                            >
                              Level 2.2
                            </Anchor>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}

              {/* <li className="has-submenu parent-parent-menu-item">
                <Anchor href="javascript:void(0)">Portfolio</Anchor>
                <span className="menu-arrow"></span>
                <ul className="submenu megamenu">
                  <li>
                    <ul>
                      <li className="megamenu-head">Modern Portfolio</li>
                      <li>
                        <a
                          href="portfolio-modern-two.html"
                          className="sub-menu-item"
                        >
                          Two Column
                        </a>
                      </li>
                      <li>
                        <Link to="/portfolio" className="sub-menu-item">
                          Portfolio
                        </Link>
                      </li>
                      <li>
                        <a
                          href="portfolio-modern-four.html"
                          className="sub-menu-item"
                        >
                          Four Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-modern-five.html"
                          className="sub-menu-item"
                        >
                          Five Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-modern-six.html"
                          className="sub-menu-item"
                        >
                          Six Column
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul>
                      <li className="megamenu-head">classNameic Portfolio</li>
                      <li>
                        <a
                          href="portfolio-classNameic-two.html"
                          className="sub-menu-item"
                        >
                          Two Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-classNameic-three.html"
                          className="sub-menu-item"
                        >
                          Three Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-classNameic-four.html"
                          className="sub-menu-item"
                        >
                          Four Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-classNameic-five.html"
                          className="sub-menu-item"
                        >
                          Five Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-classNameic-six.html"
                          className="sub-menu-item"
                        >
                          Six Column
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul>
                      <li className="megamenu-head">Creative Portfolio</li>
                      <li>
                        <a
                          href="portfolio-creative-two.html"
                          className="sub-menu-item"
                        >
                          Two Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-creative-three.html"
                          className="sub-menu-item"
                        >
                          Three Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-creative-four.html"
                          className="sub-menu-item"
                        >
                          Four Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-creative-five.html"
                          className="sub-menu-item"
                        >
                          Five Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-creative-six.html"
                          className="sub-menu-item"
                        >
                          Six Column
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul>
                      <li className="megamenu-head">Masonry Portfolio</li>
                      <li>
                        <a
                          href="portfolio-masonry-two.html"
                          className="sub-menu-item"
                        >
                          Two Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-masonry-three.html"
                          className="sub-menu-item"
                        >
                          Three Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-masonry-four.html"
                          className="sub-menu-item"
                        >
                          Four Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-masonry-five.html"
                          className="sub-menu-item"
                        >
                          Five Column
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-masonry-six.html"
                          className="sub-menu-item"
                        >
                          Six Column
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <ul>
                      <li className="megamenu-head">Portfolio Detail</li>
                      <li>
                        <a
                          href="portfolio-detail-one.html"
                          className="sub-menu-item"
                        >
                          Portfolio One
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-detail-two.html"
                          className="sub-menu-item"
                        >
                          Portfolio Two
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-detail-three.html"
                          className="sub-menu-item"
                        >
                          Portfolio Three
                        </a>
                      </li>
                      <li>
                        <a
                          href="portfolio-detail-four.html"
                          className="sub-menu-item"
                        >
                          Portfolio Four
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}

              {/* <li className="has-submenu parent-menu-item">
                <Anchor href="javascript:void(0)">Docs</Anchor>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <a href="documentation.html" className="sub-menu-item">
                      Documentation
                    </a>
                  </li>
                  <li>
                    <a href="changelog.html" className="sub-menu-item">
                      Changelog
                    </a>
                  </li>
                  <li>
                    <a href="widget.html" className="sub-menu-item">
                      Widget
                    </a>
                  </li>
                </ul>
              </li> */}

              <li>
                <Link to="/services" className="sub-menu-item">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/contact" className="sub-menu-item">
                  Contact
                </Link>
              </li>
            </ul>
            {/* <!--end navigation menu--> */}
          </div>
          {/* <!--end navigation--> */}
        </div>
        {/* <!--end container--> */}
      </nav>{" "}
      {/* <!--end header--> */}
    </>
  );
};

export default Navbar;
