import React from "react";
import { Link } from "react-router-dom";

const HeroSection = ({ img }) => {
  return (
    <section className="relative table w-full py-36 lg:py-44">
      <div className="container">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
          <div className="md:col-span-7">
            <div className="ltr:md:mr-6 rtl:md:ml-6">
              <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
              HI! WE ARE{" "}
                <span className="text-indigo-600">NAMBI TECHNOLOGIES</span>
              </h4>
              <p className="text-slate-400 text-lg max-w-xl">
                A group of talented software developers who are passionate about building commercially scalable Blockchain products.
              </p>

              <div className="mt-6">
                <Link
                  to="/contact"
                  className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2"
                >
                  <i className="uil uil-envelope"></i> Get Started
                </Link>
                {/* <a
                  href="documentation.html"
                  className="btn bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"
                >
                  <i className="uil uil-book-alt"></i> Documentation
                </a> */}
              </div>
            </div>
          </div>

          <div className="md:col-span-5">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
