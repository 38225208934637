import React from 'react'
import seo from "../../assets/images/illustrator/SEO_SVG.svg";
import mobileNotify from "../../assets/images/illustrator/Mobile_notification_SVG.svg";
import { Key, Mail, User } from 'react-feather';
const HowWorks = () => {
  return (
    <section className="relative md:py-24 py-16 bg-white dark:bg-black">
      <div className="container">
        <div className="grid grid-cols-1 text-center">
          <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            BLOCKCHAIN SERVICES
          </h3>
        </div>

        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
          <div className="lg:col-span-5">
            <img src={seo} alt="" />
          </div>
          <div className="lg:col-span-7 mt-8 md:mt-0">
            <div className="ltr:lg:ml-10 rtl:lg:mr-10">
              <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              DAPP / SMART CONTRACT DEVELOPMENT
              </h3>
              <p className="text-slate-400 max-w-xl">
                Smart contract development is all about being consistently perfect. Our five-step process helps us to repeatedly develop secure contracts for our clients.
              </p>

              <ul className="list-none text-slate-400 mt-4">
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                    DECIDE
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                    DERIVE
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                    DEVELOP
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                    DESTROY IF YOU CAN :)
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                    DELIVERY
                </li>
              </ul>

              <div className="mt-4">
                <a href="/services"
                  className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out" >
                  Find Out More{" "}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container md:mt-24 mt-16">
        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
          <div className="lg:col-span-5 md:order-2 order-1">
            <div className="ltr:lg:ml-10 rtl:lg:mr-10">
              <div className="bg-white dark:bg-slate-900 p-6 rounded-md shadow dark:shadow-gray-800">
                <img src={mobileNotify} alt="" />

                {/* <div className="mt-8">
                  <form>
                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label className="form-label font-medium">
                          Your Name : <span className="text-red-600">*</span>
                        </label>
                        <div className="form-icon relative mt-2">
                          <User className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"/>
                          <input
                            type="text"
                            className="form-input ltr:pl-12 rtl:pr-12"
                            placeholder="Name"
                            name="name"
                            required=""
                          />
                        </div>
                      </div>

                      <div className="mb-5">
                        <label className="form-label font-medium">
                          Your Email : <span className="text-red-600">*</span>
                        </label>
                        <div className="form-icon relative mt-2">
                          <Mail className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"/>
                          <input
                            type="email"
                            className="form-input ltr:pl-12 rtl:pr-12"
                            placeholder="Email"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>

                      <div className="mb-5">
                        <label className="form-label font-medium">
                          Enter Password :{" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <div className="form-icon relative mt-2">
                          <Key className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"/>
                          <input
                            type="password"
                            className="form-input ltr:pl-12 rtl:pr-12"
                            placeholder="Password"
                            required=""
                          />
                        </div>
                      </div>

                      <div className="">
                        <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">
                          Download
                        </button>
                      </div>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
          <div className="lg:col-span-7 md:order-1 order-2 mt-8 md:mt-0">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            GENERAL <br /> {" "}
              <span className="text-indigo-600">SOFTWARE DEVELOPMENT</span>
            </h3>

            <ul className="list-none text-slate-400 mt-4">
              <li className="mb-1 flex">
                <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                Ideation and Research
              </li>
              <li className="mb-1 flex">
                <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                Prototyping
              </li>
              <li className="mb-1 flex">
                <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                  UI/UX Design
              </li>
              <li className="mb-1 flex">
                <i className="uil uil-check-circle text-indigo-600 text-xl ltr:mr-2 rtl:ml-2"></i>{" "}
                  MVP development
              </li>
            </ul>

            <div className="mt-4">
              <a href="/services"
                className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out" >
                  Find Out More{" "}
                <i className="uil uil-angle-right-b align-middle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowWorks