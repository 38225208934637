import React from "react";
import BackToTop from "../components/BackToTop";
import MetaData from "../components/MetaData";
import Switcher from "../components/Switcher";
import Routers from "../routers";

const App = () => {

  return (
    <>
      <MetaData title="NambiTech: A Blockchain Company" />
      <Routers/>
      <Switcher/>
      <BackToTop />
    </>
  );
};

export default App;
