import React from 'react'
import { Link } from 'react-router-dom'
import Anchor from '../../components/Anchor'

const HeroSection = ({img}) => {
  return (
    <section
        className="relative table w-full py-36 lg:py-44 bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              About Us
            </h3>

            <p className="text-slate-300 text-lg max-w-xl mx-auto">
              A team of skilled members having work Experience in different domain like Information Technology, Blockchain, Print Media, Insurance, etc.
            </p>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 right-0 left-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">NambiTech</Link>
            </li>
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Anchor>Company</Anchor>
            </li>
            <li
              className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Aboutus
            </li>
          </ul>
        </div>
      </section>
  )
}

export default HeroSection