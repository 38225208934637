import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import aboutus from "../../assets/images/company/aboutus.jpg";
import Footer from "../../components/Footer";
import HeroSection from "./HeroSection";
import ExperienceSection from "./ExperienceSection";
import FeatureSection from "./FeatureSection";
import MeetSection from "./MeetSection";
import TeamSection from "./TeamSection";
// import MetaData from "../../components/MetaData";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <MetaData title="NambiTech: A Blockchain Company" /> */}
      <Navbar isLight={true} />
      {/* Hero section */}
      <HeroSection img={aboutus} />
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* Experience section */}
      <ExperienceSection />
      {/* Feature section */}
      <FeatureSection />
      {/* meet section */}
      {/* <MeetSection /> */}
      {/* Team section */}
      {/* <TeamSection /> */}
      <Footer />
    </>
  );
};

export default About;
