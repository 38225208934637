import React from "react";
import Anchor from "../../components/Anchor";

const Author = () => {
  return (
    <>
      <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">
        Author
      </h5>
      <div className="text-center mt-8">
        <img
          src="assets/images/client/05.jpg"
          className="h-24 w-24 mx-auto rounded-full shadow mb-4"
          alt=""
        />

        <Anchor
          href=""
          className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out"
        >
          Cristina Romsey
        </Anchor>
        <p className="text-slate-400">Content Writer</p>
      </div>
    </>
  );
};

export default Author;
