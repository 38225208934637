import React, { useEffect } from "react";
import Anchor from "../../components/Anchor";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import blogDetails from "../../assets/images/blog/bg.jpg";
import MetaData from "../../components/MetaData";
import HeroSection from "./HeroSection";
import Blog from "./Blog";

const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <MetaData title="NambiTech: A Blockchain Company" /> */}
      <Navbar isLight={true} />
      <HeroSection img={blogDetails} />
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="relative md:py-24 py-16">
        <Blog />
        <div className="container md:mt-24 mt-16">
          <div className="md:flex justify-center">
            <div className="lg:w-2/3 text-center">
              <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold mb-6">
                Subscribe our weekly subscription
              </h3>

              <p className="text-slate-400 max-w-xl mx-auto">
                Add some text to explain benefits of subscripton on your
                services. We'll send you the best of our blog just once a
                weekly.
              </p>

              <div className="mt-8">
                <div className="text-center subcribe-form">
                  <form className="relative mx-auto max-w-xl">
                    <input
                      type="email"
                      id="subemail"
                      name="name"
                      className="pt-4 ltr:pr-40 rtl:pl-40 pb-4 ltr:pl-6 rtl:pr-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white/70 dark:bg-slate-900/70 border border-gray-100 dark:border-gray-700"
                      placeholder="Enter your email id.."
                    />
                    <button
                      type="submit"
                      className="btn absolute top-[2px] ltr:right-[3px] rtl:left-[3px] h-[46px] bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full"
                    >
                      Subcribe Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogDetails;
