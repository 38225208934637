import React from "react";
import { useEffect } from "react";
import Anchor from "../Anchor";
// import { Link } from "react-router-dom";

const BackToTop = () => {
  useEffect(() => {
    document.getElementById("back-to-top").addEventListener("click", () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
  }, []);

  return (
    <>
      <Anchor
        id="back-to-top"
        className="back-to-top fixed hidden hover:cursor-pointer text-lg rounded-full z-10 bottom-5 ltr:right-5 rtl:left-5 h-9 w-9 text-center bg-indigo-600 text-white leading-9"
      >
        <i className="uil uil-arrow-up"></i>
      </Anchor>
    </>
  );
};

export default BackToTop;
