import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { activateMenu } from "../components/Navbar";
import About from "../containers/About.container";
import Blog from "../containers/Blog.container";
import BlogDetails from "../containers/BlogDetails.container";
import Contact from "../containers/Contact.container";
import Home from "../containers/Home.container";
import Portfolio from "../containers/Portfolio.container";
import Services from "../containers/Services.container";
import Team from "../containers/Team.container";
// import Testimonial from "../containers/Testimonial.container";

//   {
//     path: "/",
//     element: <Home />,
//   },
//   {
//     path: "/portfolio",
//     element: <Portfolio />,
//   },
//   {
//     path: "/about",
//     element: <About />,
//   },
//   {
//     path: "/services",
//     element: <Services />,
//   },
//   {
//     path: "/team",
//     element: <Team />,
//   },
//   {
//     path: "/testimonial",
//     element: <Testimonial />,
//   },
//   {
//     path: "/contact",
//     element: <Contact />,
//   },
//   {
//     path: "/blog",
//     element: <Blog />,
//   },
//   {
//     path: "/blog-detail",
//     element: <BlogDetails />,
//   },
// ]);
const Routers = () => {
  //***************Back To Top Functionality***************/
  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
    function scrollFunction() {
      var mybutton = document.getElementById("back-to-top");
      if (mybutton != null) {
        if (
          document.body.scrollTop > 500 ||
          document.documentElement.scrollTop > 500
        ) {
          mybutton.classList.add("block");
          mybutton.classList.remove("hidden");
        } else {
          mybutton.classList.add("hidden");
          mybutton.classList.remove("block");
        }
      }
    }
  }, []);
  // ************* Loader ****************
  window.addEventListener("load", fn, false);
  function fn() {
    // Preloader
    if (document.getElementById("preloader")) {
      setTimeout(() => {
        document.getElementById("preloader").style.visibility = "hidden";
        document.getElementById("preloader").style.opacity = "0";
      }, 350);
    }
    // Menus
    activateMenu();
  }
  useEffect(() => {
    // Switch Theme
    function changeTheme(e) {
      e.preventDefault();
      const htmlTag = document.getElementsByTagName("html")[0];

      if (htmlTag.class.includes("dark")) {
        htmlTag.class = "light";
      } else {
        htmlTag.class = "dark";
      }
    }

    const switcher = document.getElementById("theme-mode");
    switcher?.addEventListener("click", changeTheme);

    document.getElementById("chk").addEventListener("change", changeTheme);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // var hist = createBrowserHistory
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        {/* <Route path="/portfolio" element={<Portfolio />} /> */}
        <Route path="/about-us" element={<About />} />
        <Route path="/team" element={<Team />} />
        {/* <Route path="/testimonial" element={<Testimonial />} /> */}
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-detail" element={<BlogDetails />} />
      </Routes>
    </Router>
  );
};

export default Routers;
