import React from "react";
import { Mail, MessageCircle, User } from "react-feather";

const WriteComment = () => {
  return (
    <div className="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
      <h5 className="text-lg font-semibold">Leave A Comment:</h5>

      <form className="mt-8">
        <div className="grid lg:grid-cols-12 lg:gap-6">
          <div className="lg:col-span-6 mb-5">
            <div className="ltr:text-left rtl:text-right">
              <label for="name" className="font-semibold">
                Your Name:
              </label>
              <div className="form-icon relative mt-2">
                <User className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4" />
                {/* <i
                  data-feather="user"
                  className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"
                ></i> */}
                <input
                  name="name"
                  id="name"
                  type="text"
                  className="form-input ltr:pl-11 rtl:pr-11"
                  placeholder="Name :"
                />
              </div>
            </div>
          </div>

          <div className="lg:col-span-6 mb-5">
            <div className="ltr:text-left rtl:text-right">
              <label for="email" className="font-semibold">
                Your Email:
              </label>
              <div className="form-icon relative mt-2">
                <Mail className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4" />
                {/* <i
                  data-feather="mail"
                  className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"
                ></i> */}
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="form-input ltr:pl-11 rtl:pr-11"
                  placeholder="Email :"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div className="mb-5">
            <div className="ltr:text-left rtl:text-right">
              <label for="comments" className="font-semibold">
                Your Comment:
              </label>
              <div className="form-icon relative mt-2">
                <MessageCircle className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4" />
                {/* <i
                  data-feather="message-circle"
                  className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"
                ></i> */}
                <textarea
                  name="comments"
                  id="comments"
                  className="form-input ltr:pl-11 rtl:pr-11 h-28"
                  placeholder="Message :"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          id="submit"
          name="send"
          className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full"
        >
          Send Message
        </button>
      </form>
    </div>
  );
};

export default WriteComment;
