import React, { useEffect } from "react";
import Anchor from "../../components/Anchor";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import team from "../../assets/images/team.jpg";
import { TeamData } from "../../resource/teamData";
import { Linkedin, Twitter } from "react-feather";

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar isLight={true} />
      <section
        className="relative table w-full py-36 lg:py-44 bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${team})` }}
      >
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Team Member
            </h3>

            <p className="text-slate-300 text-lg max-w-xl mx-auto">
              Each team member is special to us. We believe that everyone possesses one or more qualities that we cultivate in order to create success stories for our clients.
            </p>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 right-0 left-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <a href="/">NambiTech</a>
            </li>
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Anchor href="">Company</Anchor>
            </li>
            <li
              className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Team
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-12 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src="assets/images/about/ab03.jpg"
                      className="shadow rounded-md"
                      alt=""
                    />
                    <img
                      src="assets/images/about/ab02.jpg"
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img
                      src="assets/images/about/ab01.jpg"
                      className="shadow rounded-md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6">
              <div className="ltr:lg:ml-5 rtl:lg:mr-5">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                  Who we are ?
                </h3>

                <p className="text-slate-400 max-w-xl">
                We are a group of professional engineers and marketers who founded this company with the goal of providing clients with affordable, high-quality services. We are here to make a big name by providing world-class services. 
              </p>
<br/>
              <p className="text-slate-400 max-w-xl">
                We specialize in multiple technologies like Solidity, Nodejs/NestJS, ReactJ, Angular2+, PHP Laravel, WordPress, Asp.net, Relational and Non-Relational Database PostgreSQL, MySQL, MongoDB, and SQLite.
              </p>
<br/>
              <p className="text-slate-400 max-w-xl">
                We have experience in varied domains which include DeFI, Insurance, PrintMedia, E-commerce, Supply Chain, Manufacturing, etc.
              </p>
<br/>
              <p className="text-slate-400 max-w-xl">
                We don't want to brag about what we're doing now, but we want you to keep an eye on this section as we add customer success stories. 
              </p>

                <div className="mt-6">
                  <a
                    href="/contact"
                    className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2"
                  >
                    <i className="uil uil-envelope"></i> Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Our Professional Team
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">
              Start working with Tailwind CSS that can provide everything you
              need to generate awareness, drive traffic, connect.
            </p> */}
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-8 gap-[30px]">
            {TeamData.map((d, i) => (
              <div className="lg:col-span-3 md:col-span-6" key={i + 3}>
                <div className="team p-6 rounded-md shadow-md dark:shadow-gray-800 dark:border-gray-700 bg-white dark:bg-slate-900 relative">
                  <div className="absolute inset-0 bg-indigo-600/10 dark:bg-indigo-600/30 rounded-md -mt-[10px] ltr:-ml-[10px] rtl:-mr-[10px] h-[98%] w-[98%] -z-1"></div>
                  <img
                    src={d.pic}
                    className="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800"
                    alt=""
                  />

                  <div className="content mt-4">
                    <Anchor
                      href=""
                      className="text-lg font-medium hover:text-indigo-600 block"
                    >
                      {d.name}
                    </Anchor>
                    <span className="text-slate-400 block">
                      {d.designation}
                    </span>

                    <p className="text-slate-400 mt-4">{d.desc}</p>

                    <ul className="list-none mt-4">
                      {d.icons?.map((icon, idx) => (
                        <li className="inline" key={idx + 10}>
                          <Anchor
                            href={d.socialLink.Linkedin}
                            className={d.iconClassName}
                          >
                            {icon}
                          </Anchor>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Have Question ? Get in touch!
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              Start working with Tailwind CSS that can provide everything you
              need to generate awareness, drive traffic, connect.
            </p>

            <div className="mt-6">
              <a
                href="contact-one.html"
                className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4"
              >
                <i className="uil uil-phone"></i> Contact us
              </a>
            </div>
          </div>
        </div> */}
      </section>
      <Footer />
    </>
  );
};

export default Team;
