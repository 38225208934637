import React from "react";
import Anchor from "../../components/Anchor";

const RecentPost = () => {
  return (
    <>
      <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">
        Recent post
      </h5>
      <div className="flex items-center mt-8">
        <img
          src="assets/images/blog/06.jpg"
          className="h-16 rounded-md shadow dark:shadow-gray-800"
          alt=""
        />

        <div className="ltr:ml-3 rtl:mr-3">
          <Anchor href="" className="font-semibold hover:text-indigo-600">
            Consultant Business
          </Anchor>
          <p className="text-sm text-slate-400">1st May 2022</p>
        </div>
      </div>

      <div className="flex items-center mt-4">
        <img
          src="assets/images/blog/07.jpg"
          className="h-16 rounded-md shadow dark:shadow-gray-800"
          alt=""
        />

        <div className="ltr:ml-3 rtl:mr-3">
          <Anchor href="" className="font-semibold hover:text-indigo-600">
            Grow Your Business
          </Anchor>
          <p className="text-sm text-slate-400">1st May 2022</p>
        </div>
      </div>

      <div className="flex items-center mt-4">
        <img
          src="assets/images/blog/08.jpg"
          className="h-16 rounded-md shadow dark:shadow-gray-800"
          alt=""
        />

        <div className="ltr:ml-3 rtl:mr-3">
          <Anchor href="" className="font-semibold hover:text-indigo-600">
            Look On The Glorious Balance
          </Anchor>
          <p className="text-sm text-slate-400">1st May 2022</p>
        </div>
      </div>
    </>
  );
};

export default RecentPost;
