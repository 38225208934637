/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import banner from "../../assets/images/portfolio/bg-inner.jpg";
import { Link } from "react-router-dom";
import Anchor from "../../components/Anchor/index";
import { Category, PortfolioData } from "../../resource/portfolioData";
import Filter from "./filter";
import { AnimatePresence, motion } from "framer-motion";
import { SRLWrapper } from "simple-react-lightbox";

const Portfolio = () => {
  const [selectedBtn, setSelectedBtn] = useState("sfs9f9se");
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(PortfolioData);
  }, []);

  const handleFiltered = (selectedItem) => {
    const filtered = PortfolioData.filter((item) => {
      if (selectedItem.toLowerCase() === "all") {
        return data;
      } else if (item.category.toLowerCase() === selectedItem.toLowerCase()) {
        return item;
      }
    });
    setData(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <MetaData title="NambiTech: A Blockchain Company" /> */}
      <Navbar isLight={true} />
      <section
        className="relative table w-full py-32 lg:py-40 bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-3 text-3xl leading-normal font-medium text-white">
              Portfolio & Works
            </h3>

            <p className="text-slate-300 text-lg max-w-xl mx-auto">
              Showcase of Our Awesome Works in Four Columns
            </p>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 right-0 left-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Techwind</Link>
            </li>
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Anchor>Portfolio</Anchor>
            </li>
            <li
              className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Modern
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="grid grid-cols-1 items-center gap-[30px]">
            <div className="filters-group-wrap text-center">
              <div className="filters-group">
                <ul className="mb-0 list-none container-filter-border-bottom filter-options">
                  {Category.map((ct) => (
                    <li
                      key={ct.id}
                      className={`inline-block text-lg font-semibold mx-2 mb-3 cursor-pointer relative border-b border-transparent text-slate-400 transition duration-500 ${
                        selectedBtn === ct.id ? "active" : ""
                      }`}
                      onClick={() => {
                        handleFiltered(ct.title);
                        setSelectedBtn(ct.id);
                      }}
                    >
                      {ct.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <SRLWrapper>
            <motion.div
              layout
              className="md:flex justify-center flex-wrap mx-auto mt-4"
            >
              <AnimatePresence>
                {data.map((pt) => (
                  <Filter
                    key={pt.id}
                    img={pt.img}
                    title={pt.title}
                    subTitle={pt.subTitle}
                  />
                ))}
              </AnimatePresence>
            </motion.div>
          </SRLWrapper>

          <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
            <div className="md:col-span-12 text-center">
              <nav aria-label="Page navigation example">
                <ul className="inline-flex items-center -space-x-px">
                  <li>
                    <Anchor className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 ltr:rounded-l-lg rtl:rounded-r-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                      <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                    </Anchor>
                  </li>
                  <li>
                    <Anchor className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                      1
                    </Anchor>
                  </li>
                  <li>
                    <Anchor className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                      2
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      aria-current="page"
                      className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-indigo-600 border border-indigo-600"
                    >
                      3
                    </Anchor>
                  </li>
                  <li>
                    <Anchor className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                      4
                    </Anchor>
                  </li>
                  <li>
                    <Anchor className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                      5
                    </Anchor>
                  </li>
                  <li>
                    <Anchor className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 ltr:rounded-r-lg rtl:rounded-l-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                      <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                    </Anchor>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <span className="text-slate-400 mb-4">
              Available for freelance projects
            </span>
            <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              Do you have designing project? <br /> Let's talk.
            </h3>

            <div className="mt-6">
              <a
                href="contact-one.html"
                className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full"
              >
                <i className="uil uil-phone"></i> Contact us
              </a>
            </div>
          </div>
        </div> */}

      </section>
      <Footer />
    </>
  );
};

export default Portfolio;
