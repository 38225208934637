import React from "react";
import {
  Facebook,
  GitHub,
  Gitlab,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from "react-feather";
import Anchor from "../../components/Anchor";

const SocialSites = () => {
  return (
    <>
      <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">
        Social sites
      </h5>
      <ul className="list-none text-center mt-8">
        <li className="inline">
          <Anchor
            href=""
            className="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"
          >
            <Facebook className="h-4 w-4" />
            {/* <i data-feather="facebook" className="h-4 w-4"></i> */}
          </Anchor>
        </li>
        <li className="inline">
          <Anchor
            href=""
            className="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"
          >
            <Instagram className="h-4 w-4" />
            {/* <i data-feather="instagram" className="h-4 w-4"></i> */}
          </Anchor>
        </li>
        <li className="inline">
          <Anchor
            href=""
            className="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"
          >
            <Twitter className="h-4 w-4" />
            {/* <i data-feather="twitter" className="h-4 w-4"></i> */}
          </Anchor>
        </li>
        <li className="inline">
          <Anchor
            href=""
            className="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"
          >
            <Linkedin className="h-4 w-4" />
            {/* <i data-feather="linkedin" className="h-4 w-4"></i> */}
          </Anchor>
        </li>
        <li className="inline">
          <Anchor
            href=""
            className="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"
          >
            <GitHub className="h-4 w-4" />
            {/* <i data-feather="github" className="h-4 w-4"></i> */}
          </Anchor>
        </li>
        <li className="inline">
          <Anchor
            href=""
            className="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"
          >
            <Youtube className="h-4 w-4" />
            {/* <i data-feather="youtube" className="h-4 w-4"></i> */}
          </Anchor>
        </li>
        <li className="inline">
          <Anchor
            href=""
            className="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"
          >
            <Gitlab className="h-4 w-4" />
            {/* <i data-feather="gitlab" className="h-4 w-4"></i> */}
          </Anchor>
        </li>
      </ul>
    </>
  );
};

export default SocialSites;
