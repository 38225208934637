import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import startup from "../../assets/images/illustrator/Startup_SVG.svg";
import HeroSection from "./HeroSection";
import SponsorSection from "./SponsorSection";
import HowWorks from "./HowWorks";
import Development from "./Development";
import PriceCard from "./PriceCard";
import FeatureSection from "../About.container/FeatureSection";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <MetaData title="NambiTech: A Blockchain Company" /> */}
      <Navbar isHome={true} />
      {/* Hero section */}
      <HeroSection img={startup} />
      {/* sponsor section */}
      {/* <SponsorSection /> */}
      {/* How Works Section */}
      <section className="relative bg-gray-50 dark:bg-slate-800">
        <div className='container'>
          <div className="grid grid-cols-1 pb-8 pt-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            OUR MISSION
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              IS TO PROVIDE AFFORDABLE AND QUALITY SERVICE TO OUR CUSTOMERS. <br/>
              WE KEPT IT SIMPLE 🙂
            </p>
          </div>
        </div>
      </section>
      <HowWorks />
      {/* Development SEction */}
      {/* <Development /> */}
      <FeatureSection />
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-gray-50 dark:text-slate-800">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M720 125L2160 0H2880V250H0V125H720Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* Prie card */}
      {/* <PriceCard /> */}
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-dark-footer">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M720 125L2160 0H2880V250H0V125H720Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
