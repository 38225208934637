import React from "react";
import Anchor from "../../components/Anchor";

const Tags = () => {
  return (
    <>
      <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">
        Tagscloud
      </h5>
      <ul className="list-none text-center mt-8">
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Business
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Finance
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Marketing
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Fashion
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Bride
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Lifestyle
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Travel
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Beauty
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Video
          </Anchor>
        </li>
        <li className="inline-block m-2">
          <Anchor
            href=""
            className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out"
          >
            Audio
          </Anchor>
        </li>
      </ul>
    </>
  );
};

export default Tags;
