import React from "react";
import { Helmet } from "react-helmet";
// import "../../assets"

const MetaData = ({ title }) => {

  return (
    <Helmet
    >
      <meta charset="UTF-8" />
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="description"
        content="Tailwind CSS Saas & Software Landing Page Template"
      />
      <meta
        name="keywords"
        content="agency, application, business, clean, creative, cryptocurrency, it solutions, modern, multipurpose, nft marketplace, portfolio, saas, software, tailwind css"
      />
      <meta name="author" content="Shreethemes" />
      <meta name="website" content="https://shreethemes.in" />
      <meta name="email" content="support@shreethemes.in" />
      <meta name="version" content="1.6.0" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      {/* <!-- favicon --> */}
      <link rel="shortcut icon" href="assets/images/favicon.ico" />
      {/* <!-- Css --> */}
      <link href="assets/libs/tobii/css/tobii.min.css" rel="stylesheet" />
      {/* <!-- Main Css --> */}
      <link
        href="assets/libs/@iconscout/unicons/css/line.css"
        type="text/css"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="assets/css/icons.css" />
      <link rel="stylesheet" href="assets/css/tailwind.css" />
      {/* <!-- JAVASCRIPTS --> */}
      <script
        crossorigin
        src="assets/libs/tobii/js/tobii.min.js"
        type="text/javascript"
      ></script>
      <script
        crossorigin
        src="assets/js/plugins.init.js"
        type="text/javascript"
      ></script>
      <script
        crossorigin
        src="assets/js/app.js"
        type="text/javascript"
      ></script>
      {/* <!-- JAVASCRIPTS --> */}
    </Helmet>
  );
};

export default MetaData;
