import React from "react";

const OurServices = () => {
  return (
    <div className="container">
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
        <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
          <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
            <i className="uil uil-airplay"></i>
          </div>

          <div className="content mt-7">
            <a href="javascript:void(0)"
              className="title h5 text-lg font-medium hover:text-indigo-600"
            >
              Smart Contracts
            </a>
            <p className="text-slate-400 mt-3">
              Writing Smart Contracts in Solidity for EVM based Chains
            </p>

            {/* <div className="mt-5">
              <a
                href="page-services.html"
                className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-arrow-right"></i>
              </a>
            </div> */}
          </div>
        </div>

        <div className="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
          <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
            <i className="uil uil-shutter"></i>
          </div>

          <div className="content mt-7">
            <a href="javascript:void(0)"
              className="title h5 text-lg font-medium hover:text-indigo-600" >
              API Development
            </a>
            <p className="text-slate-400 mt-3">
              We create APIs in different languages as per requirements. Prefered language is NodeJS/NestJS
            </p>

            {/* <div className="mt-5">
              <a
                href="page-services.html"
                className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-arrow-right"></i>
              </a>
            </div> */}
          </div>
        </div>

        <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
          <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
            <i className="uil uil-camera-plus"></i>
          </div>

          <div className="content mt-7">
            <a
              href="javascript:void(0)"
              className="title h5 text-lg font-medium hover:text-indigo-600"
            >
              UI / UX Design
            </a>
            <p className="text-slate-400 mt-3">
              We can design UI /UX in Figma and Adobe
            </p>

            {/* <div className="mt-5">
              <a
                href="javascript:void(0)"
                className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-arrow-right"></i>
              </a>
            </div> */}
          </div>
        </div>

        <div className="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
          <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
            <i className="uil uil-flower"></i>
          </div>

          <div className="content mt-7">
            <a href="javascript:void(0)"
              className="title h5 text-lg font-medium hover:text-indigo-600"
            >
              Web Development
            </a>
            <p className="text-slate-400 mt-3">
              We create web designs in HTML, CSS, JS, Tailwind and develop in ReactJS
            </p>

            {/* <div className="mt-5">
              <a
                href="javascript:void(0)"
                className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-arrow-right"></i>
              </a>
            </div> */}
          </div>
        </div>

        <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
          <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
            <i className="uil uil-cloud"></i>
          </div>

          <div className="content mt-7">
            <a
              href="javascript:void(0)"
              className="title h5 text-lg font-medium hover:text-indigo-600"
            >
              Cloud Computing
            </a>
            <p className="text-slate-400 mt-3">
              We can help setup cloud infrastructures on AWS, Azure, Google Cloud, etc.
            </p>

            {/* <div className="mt-5">
              <a
                href="page-services.html"
                className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-arrow-right"></i>
              </a>
            </div> */}
          </div>
        </div>

        <div className="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center">
          <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
            <i className="uil uil-comment"></i>
          </div>

          <div className="content mt-7">
            <a
              href="Javascript:void(0)"
              className="title h5 text-lg font-medium hover:text-indigo-600"
            >
              WordPress
            </a>
            <p className="text-slate-400 mt-3">
              We develop wordpress projects including plugins development
            </p>

            {/* <div className="mt-5">
              <a
                href="page-services.html"
                className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
              >
                Read More <i className="uil uil-arrow-right"></i>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
