import { Facebook, Instagram, Linkedin, Twitter } from "react-feather";

export const TeamData = [
  {
    name: "Rahul Singh",
    designation: "Co-Founder",
    desc: "Head of Technology",
    pic: "assets/images/rahul.png",
    icons: [
      // <Twitter className="h-4 w-4" />,
      <Linkedin className="h-4 w-4" />,
      // <Facebook className="h-4 w-4" />,
      // <Instagram className="h-4 w-4" />,
    ],
    socialLink: {
      Linkedin: "https://www.linkedin.com/in/rahul-singh-ba9143101/"
    },
    iconClassName: "btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"
  },
  {
    name: "Rakesh Yadav",
    designation: "Co-founder",
    desc: "Chief Architect",
    pic: "assets/images/rakesh.jpeg",
    icons: [
      // <Facebook className="h-4 w-4" />,
      // <Instagram className="h-4 w-4" />,
      // <Twitter className="h-4 w-4" src="#"/>,
      <Linkedin className="h-4 w-4" />,
    ],
    socialLink: {
      Linkedin: "https://www.linkedin.com/in/yadurakesh/"
    },
    iconClassName: "btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-emerald-600 hover:bg-emerald-600 hover:text-white"
  },
  // {
  //   name: "Sofia Razaq",
  //   designation: "C.O.O.",
  //   desc: "If the distribution of letters and 'words' is random",
  //   icons: [
  //     <Facebook className="h-4 w-4" />,
  //     <Instagram className="h-4 w-4" />,
  //     <Twitter className="h-4 w-4" />,
  //     <Linkedin className="h-4 w-4" />,
  //   ],
  //   iconClassName: "btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-red-600 hover:bg-red-600 hover:text-white"
  // },
  // {
  //   name: "Micheal Carlo",
  //   designation: "Director",
  //   desc: "If the distribution of letters and 'words' is random",
  //   icons: [
  //     <Facebook className="h-4 w-4" />,
  //     <Instagram className="h-4 w-4" />,
  //     <Twitter className="h-4 w-4" />,
  //     <Linkedin className="h-4 w-4" />,
  //   ],
  //   iconClassName: "btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-sky-600 hover:bg-sky-600 hover:text-white"
  // },
  // {
  //   name: "Ronny Jofra",
  //   designation: "C.E.O.",
  //   desc: "If the distribution of letters and 'words' is random",
  //   icons: [
  //     <Facebook className="h-4 w-4" />,
  //     <Instagram className="h-4 w-4" />,
  //     <Twitter className="h-4 w-4" />,
  //     <Linkedin className="h-4 w-4" />,
  //   ],
  //   iconClassName: "btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"
  // },
  // {
  //   name: "Aliana Rosy",
  //   designation: "HR",
  //   desc: "If the distribution of letters and 'words' is random",
  //   icons: [
  //     <Facebook className="h-4 w-4" />,
  //     <Instagram className="h-4 w-4" />,
  //     <Twitter className="h-4 w-4" />,
  //     <Linkedin className="h-4 w-4" />,
  //   ],
  //   iconClassName: "btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-emerald-600 hover:bg-emerald-600 hover:text-white"
  // },
  // {
  //   name: "Sofia Razaq",
  //   designation: "C.O.O.",
  //   desc: "If the distribution of letters and 'words' is random",
  //   icons: [
  //     <Facebook className="h-4 w-4" />,
  //     <Instagram className="h-4 w-4" />,
  //     <Twitter className="h-4 w-4" />,
  //     <Linkedin className="h-4 w-4" />,
  //   ],
  //   iconClassName: "btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-red-600 hover:bg-red-600 hover:text-white"
  // },
  // {
  //   name: "Micheal Carlo",
  //   designation: "Director",
  //   desc: "If the distribution of letters and 'words' is random",
  //   icons: [
  //     <Facebook className="h-4 w-4" />,
  //     <Instagram className="h-4 w-4" />,
  //     <Twitter className="h-4 w-4" />,
  //     <Linkedin className="h-4 w-4" />,
  //   ],
  //   iconClassName: "btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-sky-600 hover:bg-sky-600 hover:text-white"
  // },
];
