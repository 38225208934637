import React from "react";
import Author from "./Author";
import BlogHeader from "./BlogHeader";
import Comment from "./Comment";
import RecentPost from "./RecentPost";
import SocialSites from "./SocialSites";
import Tags from "./Tags";
import WriteComment from "./WriteComment";

const Blog = () => {
  return (
    <div className="container">
      <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
        <div className="lg:col-span-8 md:col-span-6">
          <BlogHeader />
          <Comment />
          <WriteComment />
        </div>

        <div className="lg:col-span-4 md:col-span-6">
          <div className="sticky top-20">
            <Author />
            <RecentPost />
            <SocialSites />
            <Tags />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
